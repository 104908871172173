import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import SearchForm from './search_person_widget/search_form'
import SearchResults from './search_person_widget/search_results'
import PersonForm from './resource_form/resource_form'
import { Button } from 'react-bootstrap'
import { bootstrapSelectStyle, xFetch } from './utils'
import Select from 'react-select'

export default function AddNamedPersonWidget({
  personSjsModel,
  afterAddPerson,
  canAccessOsr = false,
  cohortsRequired = true,
  availableCohorts = [],
  initialCohortIds,
  isRelative = false,
}) {
  const [searchPageActive, setSearchPageActive] = useState(true)
  const [searchResults, setSearchResults] = useState()
  const [searchParams, setSearchParams] = useState()
  const [selectedCohortIds, setSelectedCohortIds] = useState(initialCohortIds || [])
  const [loading, setLoading] = useState(false)

  let previousRequestController

  const handleSearchFormUpdated = useCallback((params) => {
    previousRequestController && previousRequestController.abort()
    setLoading(true)
    setSearchParams(params)
    previousRequestController = new AbortController()
    xFetch(Routes.search_people_path(params), { signal: previousRequestController.signal })
      .then((data) => {
        setSearchResults(data)
        previousRequestController = undefined
        setLoading(false)
      })
  }, [])

  const createPersonByOsrId = person =>
    xFetch(Routes.create_by_osr_id_people_path(), {
      method: 'POST',
      body: JSON.stringify({
        person: {
          osr_id: person.osr_id,
        },
      }),
      headers: { 'X-will-redirect': true },
    }).then((createdPerson) => {
      addPersonToSelectedCohorts(createdPerson)
    })

  const addPersonToCohort = (personId, cohortId) =>
    xFetch(Routes.add_member_cohort_path(cohortId), {
      method: 'PATCH',
      body: JSON.stringify({
        pid: personId,
      }),
    })

  const addPersonToSelectedCohorts = (person) => {
    // add to all selected cohorts sequentially https://jrsinclair.com/articles/2019/how-to-run-async-js-in-parallel-or-sequential/
    selectedCohortIds.reduce(
      (p, cId) => p.then(() => addPersonToCohort(person.id, cId)),
      Promise.resolve(null)
    )
      .then(() => afterAddPerson(person))
  }

  const handleSelectedCohortIdsChanged = selectedCohorts => setSelectedCohortIds(selectedCohorts.map(c => c.value))

  const handleProceedToNew = () => setSearchPageActive(false)

  const handleClearSearch = useCallback(() => {
    setSearchParams()
    setSearchResults()
  }, [])

  const handleBackToSearch = () => setSearchPageActive(true)

  const cohortOptions = availableCohorts.map((c) => {
    return {
      value: c.id,
      label: c.name,
    }
  })

  const selectedCohortOptions = cohortOptions.filter(co => _.includes(selectedCohortIds, co.value))

  const actionCell = person => person.id
    ? _.isEmpty(selectedCohortIds)
      ? isRelative
        ? (
          <Button onClick={() => afterAddPerson(person)} size="sm" variant="outline-primary">
            <i className="fas fa-plus" />
            {' '}
            link
          </Button>
          )
        : (
          <Button onClick={() => window.location = person.path} size="sm" variant="outline-primary">
            <i className="fas fa-eye" />
            {' '}
            show
          </Button>
          )
      : (
        <Button className="mr-1" onClick={() => addPersonToSelectedCohorts(person)} size="sm" variant="outline-primary">
          <i className="fas fa-plus-circle mr-1" />
          {isRelative ? 'add to cohorts & link' : 'add to cohorts'}
        </Button>
        )
    : (
      <Button onClick={() => createPersonByOsrId(person)} size="sm" variant="outline-primary">
        <i className="fas fa-plus-circle mr-1" />
        { isRelative
          ? 'add to CGP & link'
          : _.isEmpty(selectedCohortIds) ? 'add to CGP' : 'add to CGP & cohorts'}
      </Button>
      )

  return (
    <>
      <label>{`Add to the following cohorts ${cohortsRequired ? '*' : ''}`}</label>
      <Select
        className="mb-4"
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        defaultValue={selectedCohortOptions}
        hideSelectedOptions={false}
        isDisabled={!_.isNil(initialCohortIds)}
        isMulti={true}
        onChange={handleSelectedCohortIdsChanged}
        options={cohortOptions}
        placeholder="Please select ...."
        styles={bootstrapSelectStyle}
      />
      {
        (!cohortsRequired || (cohortsRequired && selectedCohortIds.length))
          ? searchPageActive
            ? (
              <>
                <SearchForm
                  canAccessOsr={canAccessOsr}
                  handleClearSearch={handleClearSearch}
                  handleSearchFormUpdated={handleSearchFormUpdated}
                />
                {
                  searchParams
                    ? (
                      <SearchResults
                        actionCell={actionCell}
                        loading={loading}
                        searchResults={searchResults}
                        showCgpResults={_.includes(searchParams?.target, 'cgp')}
                        showOsrResults={_.includes(searchParams?.target, 'osr')}
                        willAdd={!_.isEmpty(selectedCohortIds)}
                      />
                      )
                    : null
                }
                {
                searchParams
                  ? (
                    <div className="d-flex flex-row-reverse">
                      <h5>
                        Cannot find who you are looking for?
                        <button className="btn btn-primary mx-2" onClick={handleProceedToNew} type="button">Create a new Person</button>
                      </h5>
                    </div>
                    )
                  : null
              }

              </>
              )
            : (
              <>
                <PersonForm
                  actionPaths={{ save: '/people' }}
                  afterSaveAction={addPersonToSelectedCohorts}
                  cardLayout={true}
                  resourceName="named_person"
                  sjsData={_.omit(searchParams, ['target'])}
                  sjsModel={personSjsModel}
                />
                <Button onClick={handleBackToSearch} variant="link">
                  Back to search
                </Button>
              </>
              )
          : null
      }
    </>
  )
}

AddNamedPersonWidget.propTypes = {
  personSjsModel: PropTypes.object.isRequired,
  afterAddPerson: PropTypes.func.isRequired,
  canAccessOsr: PropTypes.bool,
  cohortsRequired: PropTypes.bool,
  availableCohorts: PropTypes.array,
  initialCohortIds: PropTypes.array,
  isRelative: PropTypes.bool,
}
