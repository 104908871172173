import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PersonForm from './resource_form/resource_form'
import { bootstrapSelectStyle, xFetch } from './utils'
import Select from 'react-select'

export default function AddPersonWidget({
  personSjsModel,
  afterAddPerson,
  availableCohorts,
  initialCohortIds,
  cohortsRequired = true,
}) {
  const [selectedCohortIds, setSelectedCohortIds] = useState(initialCohortIds || [])

  const cohortOptions = availableCohorts.map((c) => {
    return {
      value: c.id,
      label: c.name,
    }
  })

  const selectedCohortOptions = cohortOptions.filter(co => _.includes(selectedCohortIds, co.value))

  const addPersonToCohort = (personId, cohortId) =>
    xFetch(Routes.add_member_cohort_path(cohortId), {
      method: 'PATCH',
      body: JSON.stringify({
        pid: personId,
      }),
    })

  const addPersonToSelectedCohorts = (person) => {
    // add to all selected cohorts sequentially https://jrsinclair.com/articles/2019/how-to-run-async-js-in-parallel-or-sequential/
    selectedCohortIds.reduce(
      (p, cId) => p.then(() => addPersonToCohort(person.id, cId)),
      Promise.resolve(null)
    )
      .then(() => afterAddPerson(person))
  }

  return (
    <>
      <label>{`Add to the following cohorts ${cohortsRequired ? '*' : ''}`}</label>
      <Select
        className="mb-4"
        // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        defaultValue={selectedCohortOptions}
        hideSelectedOptions={false}
        isDisabled={!_.isNil(initialCohortIds)}
        isMulti={true}
        onChange={newSelectedOptions => setSelectedCohortIds(_.map(newSelectedOptions, 'value'))}
        options={cohortOptions}
        placeholder="Please select ...."
        styles={bootstrapSelectStyle}
      />
      {
      cohortsRequired && selectedCohortIds.length
        ? (
          <PersonForm
            actionPaths={{ save: '/people' }}
            afterSaveAction={addPersonToSelectedCohorts}
            cardLayout={true}
            resourceName="person"
            sjsData={{}}
            sjsModel={personSjsModel}
          />
          )
        : null
    }
    </>
  )
}

AddPersonWidget.propTypes = {
  personSjsModel: PropTypes.object.isRequired,
  afterAddPerson: PropTypes.func.isRequired,
  availableCohorts: PropTypes.array,
  initialCohortIds: PropTypes.array,
  cohortsRequired: PropTypes.bool,
}
