import React, { useContext } from 'react'
import MultiStepFormContext from '../MultiStepFormContext'

function SjsBuilder({
}) {
  const { mergeData, extractSjsModel, extractSjsData, errors } = useContext(MultiStepFormContext)
  const dataFields = ['model']
  const questions = dataFields

  return (
    <p>sjs builder</p>
  )
}
export default SjsBuilder
