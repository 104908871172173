import React from 'react'
import PropTypes from 'prop-types'
import Form from './form'
import { Userstamp } from '../utils'
import { xFetch } from '../utils'

export default class ResourceForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: this.initialStatus(),
      data: this.props.sjsData,
      surveyAction: null,
      errors: [],
    }
  }

  static defaultProps = {
    cardLayout: false,
    actionPaths: {},
  }

  static propTypes = {
    sjsModel: PropTypes.object.isRequired,
    sjsData: PropTypes.object.isRequired,
    actionPaths: PropTypes.object.isRequired,
    resourceName: PropTypes.string.isRequired,
    resourceHumanName: PropTypes.string,
    cardLayout: PropTypes.bool,
    afterSaveAction: PropTypes.func,
  }

  getResourceHumanName() {
    return this.props.resourceHumanName || _.capitalize(this.props.resourceName)
  }

  initialStatus() {
    if (_.isEmpty(this.props.sjsData)) {
      return 'new'
    }
    else {
      if (this.props.sjsData.id) {
        return 'saved'
      }
      else {
        return 'changed'
      }
    }
  }

  doAction(action) {
    switch (action) {
      case 'save':
        this.setState({ surveyAction: 'complete' })
        break
      case 'reset':
        this.setState({ surveyAction: 'reset', data: this.props.sjsData, status: this.initialStatus() })
        break
    }
  }

  handleChange = (newData) => {
    this.setState({ data: newData, surveyAction: null, status: !_.isEqual(this.props.sjsData, newData) ? 'changed' : this.initialStatus() })
    console.debug(`new data ${JSON.stringify(newData)}`)
  }

  handleSave = (dataToSend) => {
    const payload = {}
    payload[this.props.resourceName] = dataToSend

    let headers = {}
    if (!this.props.afterSaveAction) {
      headers['X-will-redirect'] = true
    }

    // console.debug('about to send -> ' + JSON.stringify(payload))
    this.setState({
      surveyAction: null,
      status: 'saving',
    }, () =>
      xFetch(this.props.actionPaths.save, {
        method: (this.props.sjsData.id ? 'PATCH' : 'POST'),
        headers: headers,
        body: JSON.stringify(payload),
      })
        .then((body) => {
          if (this.props.afterSaveAction) {
            this.props.afterSaveAction(body)
          }
          else {
            body.path ? window.location = body.path : window.location.reload()
          }
        })
        .catch((error) => {
          this.setState({
            surveyAction: 'addErrors',
            status: 'changed',
            errors: error.msgs,
          }, () => toastr.error(`${this.getResourceHumanName()} cannot be saved`))
        })
    )
  }

  render() {
    console.debug('RERENDERING FORM CONTAINER')

    return (
      <div className={this.props.cardLayout ? 'card card-default mb-4' : 'mb-4'}>
        <div className={this.props.cardLayout ? 'card-body' : ''}>
          <Form
            data={this.state.data}
            errors={this.state.errors}
            handleChange={this.handleChange}
            handleSave={this.handleSave}
            model={this.props.sjsModel}
            originalData={this.props.sjsData}
            surveyAction={this.state.surveyAction}
          />

          { this.props.actionPaths.save != null
            ? (
              <div className="row mt-2">
                <div className="col-12 text-right">
                  <button className="btn btn-link" disabled={this.state.status != 'changed'} onClick={() => this.doAction('reset')} type="button">
                    <i className="fas fa-undo-alt" />
                    {' '}
                    Undo Changes
                  </button>
                  <button className={`btn btn-primary btn-sjs-save ${this.state.status} mr-2`} disabled={this.state.status != 'changed'} onClick={() => this.doAction('save')} type="button" />
                </div>
              </div>
              )
            : null}
        </div>
        <div className="card-footer mt-2">
          <Userstamp {..._.pick(this.state.data, ['created_at', 'creator', 'updated_at', 'updater'])} />
        </div>
      </div>
    )
  }
}
