import { defaultCss } from 'survey-core/plugins/bootstrap-integration'
import { diff } from 'jsondiffpatch/with-text-diffs'
import React from 'react'
import { ReactElementFactory } from 'survey-react-ui'
import QuestionHelper from '../components/question_helper'
import * as SurveyCustomFunctions from './custom_functions'
import * as AsyncSurveyCustomFunctions from './async_custom_functions'
import * as CustomComponents from './custom_components'
import addWidgets from './widgets'
import { RendererFactory } from 'survey-core'

const getCustomizedBootstrapCss = function () {
  // console.debug(JSON.stringify(defaultCss,null,2))

  let customizedCss = _.cloneDeep(defaultCss)

  customizedCss.question.title += 'sv_qstn_title'

  customizedCss.panel.title += ' h5'
  customizedCss.panel.container += ' mb-2'

  customizedCss.boolean.label += ' font-weight-light'
  customizedCss.boolean.slider += ' bg-white'

  customizedCss.checkbox.label += ' font-weight-light'

  customizedCss.matrixdropdown.headerCell += ' border-top-0'

  customizedCss.matrixdynamic.button = 'btn'
  customizedCss.matrixdynamic.headerCell += ' border-top-0'
  customizedCss.matrixdynamic.buttonAdd = 'btn-outline-dark btn-sm'
  customizedCss.matrixdynamic.buttonRemove = 'btn-outline-danger btn-sm'

  customizedCss.paneldynamic.button = 'btn'
  customizedCss.paneldynamic.buttonAdd = 'btn-outline-dark btn-sm'
  customizedCss.paneldynamic.buttonRemove += ' btn-outline-danger btn-sm mr-3'

  customizedCss.radiogroup.label += ' font-weight-light'
  customizedCss.radiogroup.clearButton += ' btn btn-link'
  customizedCss.radiogroup.controlLabel += 'ml-1'

  customizedCss.file.removeButton = 'sv-hidden'

  // console.debug(JSON.stringify(diff(defaultCss, customizedCss),null,2))

  return customizedCss
}

// this is downloaded from sjs builder theme tab: flat light theme without panels
// keep it as it is and edit the css file if necessary
const FlatLightPanelless = {
  backgroundImage: '',
  backgroundImageFit: 'cover',
  backgroundImageAttachment: 'scroll',
  backgroundOpacity: 1,
  themeName: 'flat',
  isPanelless: true,
  colorPalette: 'light',
  cssVariables: {
    '--sjs-general-backcolor': 'rgba(246, 246, 246, 1)',
    '--sjs-general-backcolor-dark': 'rgba(235, 235, 235, 1)',
    '--sjs-general-backcolor-dim': 'rgba(246, 246, 246, 1)',
    '--sjs-general-backcolor-dim-light': 'rgba(255, 255, 255, 1)',
    '--sjs-general-backcolor-dim-dark': 'rgba(235, 235, 235, 1)',
    '--sjs-general-forecolor': 'rgba(0, 0, 0, 0.91)',
    '--sjs-general-forecolor-light': 'rgba(0, 0, 0, 0.45)',
    '--sjs-general-dim-forecolor': 'rgba(0, 0, 0, 0.91)',
    '--sjs-general-dim-forecolor-light': 'rgba(0, 0, 0, 0.45)',
    '--sjs-primary-backcolor': 'rgba(85, 181, 52, 1)',
    '--sjs-primary-backcolor-light': 'rgba(85, 181, 52, 0.1)',
    '--sjs-primary-backcolor-dark': 'rgba(64, 159, 31, 1)',
    '--sjs-primary-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-primary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
    '--sjs-base-unit': '8px',
    '--sjs-corner-radius': '4px',
    '--sjs-secondary-backcolor': 'rgba(255, 152, 20, 1)',
    '--sjs-secondary-backcolor-light': 'rgba(255, 152, 20, 0.1)',
    '--sjs-secondary-backcolor-semi-light': 'rgba(255, 152, 20, 0.25)',
    '--sjs-secondary-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-secondary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
    '--sjs-shadow-small': '0px 0px 0px 1px rgba(0, 0, 0, 0.15)',
    '--sjs-shadow-small-reset': '0px 0px 0px 0px rgba(0, 0, 0, 0.15)',
    '--sjs-shadow-medium': '0px 0px 0px 1px rgba(0, 0, 0, 0.1),0px 2px 6px 0px rgba(0, 0, 0, 0.1)',
    '--sjs-shadow-large': '0px 8px 16px 0px rgba(0, 0, 0, 0.1)',
    '--sjs-shadow-inner': '0px 0px 0px 1px rgba(0, 0, 0, 0.12)',
    '--sjs-shadow-inner-reset': '0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
    '--sjs-border-light': 'rgba(0, 0, 0, 0.12)',
    '--sjs-border-default': 'rgba(0, 0, 0, 0.12)',
    '--sjs-border-inside': 'rgba(0, 0, 0, 0.16)',
    '--sjs-special-red': 'rgba(229, 10, 62, 1)',
    '--sjs-special-red-light': 'rgba(229, 10, 62, 0.1)',
    '--sjs-special-red-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-green': 'rgba(25, 179, 148, 1)',
    '--sjs-special-green-light': 'rgba(25, 179, 148, 0.1)',
    '--sjs-special-green-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-blue': 'rgba(67, 127, 217, 1)',
    '--sjs-special-blue-light': 'rgba(67, 127, 217, 0.1)',
    '--sjs-special-blue-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-yellow': 'rgba(255, 152, 20, 1)',
    '--sjs-special-yellow-light': 'rgba(255, 152, 20, 0.1)',
    '--sjs-special-yellow-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-article-font-xx-large-textDecoration': 'none',
    '--sjs-article-font-xx-large-fontWeight': '700',
    '--sjs-article-font-xx-large-fontStyle': 'normal',
    '--sjs-article-font-xx-large-fontStretch': 'normal',
    '--sjs-article-font-xx-large-letterSpacing': '0',
    '--sjs-article-font-xx-large-lineHeight': '64px',
    '--sjs-article-font-xx-large-paragraphIndent': '0px',
    '--sjs-article-font-xx-large-textCase': 'none',
    '--sjs-article-font-x-large-textDecoration': 'none',
    '--sjs-article-font-x-large-fontWeight': '700',
    '--sjs-article-font-x-large-fontStyle': 'normal',
    '--sjs-article-font-x-large-fontStretch': 'normal',
    '--sjs-article-font-x-large-letterSpacing': '0',
    '--sjs-article-font-x-large-lineHeight': '56px',
    '--sjs-article-font-x-large-paragraphIndent': '0px',
    '--sjs-article-font-x-large-textCase': 'none',
    '--sjs-article-font-large-textDecoration': 'none',
    '--sjs-article-font-large-fontWeight': '700',
    '--sjs-article-font-large-fontStyle': 'normal',
    '--sjs-article-font-large-fontStretch': 'normal',
    '--sjs-article-font-large-letterSpacing': '0',
    '--sjs-article-font-large-lineHeight': '40px',
    '--sjs-article-font-large-paragraphIndent': '0px',
    '--sjs-article-font-large-textCase': 'none',
    '--sjs-article-font-medium-textDecoration': 'none',
    '--sjs-article-font-medium-fontWeight': '700',
    '--sjs-article-font-medium-fontStyle': 'normal',
    '--sjs-article-font-medium-fontStretch': 'normal',
    '--sjs-article-font-medium-letterSpacing': '0',
    '--sjs-article-font-medium-lineHeight': '32px',
    '--sjs-article-font-medium-paragraphIndent': '0px',
    '--sjs-article-font-medium-textCase': 'none',
    '--sjs-article-font-default-textDecoration': 'none',
    '--sjs-article-font-default-fontWeight': '400',
    '--sjs-article-font-default-fontStyle': 'normal',
    '--sjs-article-font-default-fontStretch': 'normal',
    '--sjs-article-font-default-letterSpacing': '0',
    '--sjs-article-font-default-lineHeight': '28px',
    '--sjs-article-font-default-paragraphIndent': '0px',
    '--sjs-article-font-default-textCase': 'none',
  },
}

const customizeCssClasses = function (cssClasses) {
  let customizedCssClasses = _.cloneDeep(cssClasses)

  console.debug(JSON.stringify(customizedCssClasses, null, 4))

  // customizedCssClasses.paneldynamic.nested += ' sd-element--panel-nested'
  // customizedCssClasses.panel.nested += ' sd-element--panel-nested'

  return customizedCssClasses
}

const setupForCollector = (SurveyCore, tmpWaitingForNetworkQuestions) => {
  console.debug('SurveyJS version', SurveyCore.Version)

  addWidgets(SurveyCore)

  // add custom functions
  _.forIn(SurveyCustomFunctions, (v, k) => SurveyCore.FunctionFactory.Instance.register(k, v))
  _.forIn(AsyncSurveyCustomFunctions, (v, k) => SurveyCore.FunctionFactory.Instance.register(k, v, true))

  // add custom components
  _.forIn(CustomComponents, v => SurveyCore.ComponentCollection.Instance.add(v))

  // because otherwise samples_url output would not work
  SurveyCore.ChoicesRestfull.EncodeParameters = false

  // the default value is 20. We need more choices for rating
  SurveyCore.settings.ratingMaximumRateValueCount = 100

  SurveyCore.settings.minWidth = '150px'

  // store pending choicesByUrl requests ...
  SurveyCore.ChoicesRestfull.onBeforeSendRequest = (choicesByUrl, options) => {
    options.request.setRequestHeader('X_REQUESTED_WITH', 'xmlhttprequest') // so that my server can recognize it as xhr https://github.com/surveyjs/survey-library/issues/3807
    window.survey
      ? window.survey.addWaitingForNetworkQuestion(choicesByUrl.loadingOwner.id) // ... into survey if it's loaded
      : tmpWaitingForNetworkQuestions.push(choicesByUrl.loadingOwner.id) // ... into a temp var if survey is not loaded
  }

  // property to exclude questions from visualization data and export if desired
  SurveyCore.Serializer.addProperty('question', {
    name: 'exportable:boolean',
    default: true,
  })
  SurveyCore.Serializer.findProperty('choicesByUrl', 'allowEmptyResponse').defaultValue = true
  SurveyCore.Serializer.findProperty('matrixdynamic', 'rowCount').defaultValue = 0
  SurveyCore.Serializer.findProperty('radiogroup', 'colCount').defaultValue = 0
  SurveyCore.Serializer.getProperty('radiogroup', 'showClearButton').defaultValue = true

  // property to specify a custom excel sheetName in export (useful to override long name that would be truncated)
  const sheetNameProp = {
    name: 'sheetName',
    type: 'string',
  }
  SurveyCore.Serializer.addProperty('checkbox', sheetNameProp)
  SurveyCore.Serializer.addProperty('matrixdynamic', sheetNameProp)
  SurveyCore.Serializer.addProperty('paneldynamic', sheetNameProp)

  // property to specify the paneldynamic question that will be used to sort the array data elements
  const sortByProp = {
    name: 'sortBy',
    type: 'string',
  }
  SurveyCore.Serializer.addProperty('paneldynamic', sortByProp)

  SurveyCore.Serializer.addProperty('choicesByUrl', {
    name: 'jsonpath',
    type: 'string',
  })
}

const setupForForm = (SurveyCore, tmpWaitingForNetworkQuestions) => {
  setupForCollector(SurveyCore, tmpWaitingForNetworkQuestions)

  RendererFactory.Instance.registerRenderer(
    'radiogroup',
    'cgp-radiogroup',
    'sv-cgp-radiogroup',
    true
  )

  RendererFactory.Instance.registerRenderer(
    'dropdown',
    'reactselect',
    'sv-reactselect',
    true
  )

  RendererFactory.Instance.registerRenderer(
    'checkbox',
    'reactselect',
    'sv-reactselect',
  )

  // just to keep backcompatibility with select2
  RendererFactory.Instance.registerRenderer(
    'dropdown',
    'select2',
    'sv-reactselect',
  )
  // just to keep backcompatibility with select2
  RendererFactory.Instance.registerRenderer(
    'checkbox',
    'select2',
    'sv-reactselect',
  )

  RendererFactory.Instance.registerRenderer(
    'expression',
    'cgp-expression',
    'sv-cgp-expression',
    true
  )

  console.debug(RendererFactory.Instance)

  SurveyCore.Serializer.addProperty('question', {
    name: 'helper:string',
  })
}

const setupForQuestionnaire = (SurveyCore, tmpWaitingForNetworkQuestions) => {
  setupForCollector(SurveyCore, tmpWaitingForNetworkQuestions)
}

const setupForResourceForm = (SurveyCore) => {
  addWidgets(SurveyCore)

  // add custom functions
  _.forIn(SurveyCustomFunctions, (v, k) => SurveyCore.FunctionFactory.Instance.register(k, v))

  SurveyCore.StylesManager.applyTheme('bootstrap')

  SurveyCore.Serializer.addProperty('question', {
    name: 'rootClassSuffix:string',
    default: '',
  })

  SurveyCore.Serializer.addProperty('panel', {
    name: 'rootClassSuffix:string',
    default: '',
  })

  SurveyCore.ChoicesRestfull.onBeforeSendRequest = (choicesByUrl ,options) => {
    options.request.setRequestHeader('X_REQUESTED_WITH', 'xmlhttprequest') // so that my server can recognize it as xhr https://github.com/surveyjs/survey-library/issues/3807
  }

  RendererFactory.Instance.registerRenderer(
    'dropdown',
    'reactselect',
    'sv-reactselect',
    true
  )

  RendererFactory.Instance.registerRenderer(
    'checkbox',
    'reactselect',
    'sv-reactselect',
    // true
  )
}

ReactElementFactory.Instance.registerElement('question-helper', (props) => {
  return React.createElement(QuestionHelper, props)
})

function addHelperFeature(survey) {
  survey.onGetQuestionTitleActions.add((survey, options) => {
    if (!options.question.helper) return
    options.titleActions = [{
      id: 'question-helper',
      component: 'question-helper',
      data: {
        text: options.question.helper,
      },
    }]
  })
}

export { getCustomizedBootstrapCss, FlatLightPanelless, customizeCssClasses, addHelperFeature, setupForForm, setupForQuestionnaire, setupForResourceForm }
