import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import * as SurveyCore from 'survey-core'
import * as SjsUtils from '../../sjs_extensions/sjs_utils'
import { Survey } from 'survey-react-ui'
import { markdownHandler, Userstamp, xFetch, keepSessionAlive, WithSpinner } from '../utils'
import { diff } from 'jsondiffpatch/with-text-diffs'
import { Alert, Tooltip, OverlayTrigger, ButtonGroup, ButtonToolbar, Modal, Button } from 'react-bootstrap'
import MultiStepFormContext from './MultiStepFormContext'

function StepForm({
  attrs,
  questions,
  errors,
}) {
  const { updateData, extractSjsModel, extractSjsData } = useContext(MultiStepFormContext)
  const buildSurvey = () => {
    const baseSjsModel = {
      clearInvisibleValues: 'onHidden',
      requiredText: '* ',
      showNavigationButtons: 'none',
      showCompletedPage: false,
      showQuestionNumbers: 'off',
      checkErrorsMode: 'onValueChanged',
      questionDescriptionLocation: 'underInput',
    }
    const mergedSjsModel = _.merge(baseSjsModel, extractSjsModel(questions))

    // console.debug('Creating survey...')
    let survey = new SurveyCore.Model(mergedSjsModel)

    // console.debug('Setting up survey...')
    setupSurvey(survey)

    return survey
  }

  const setupSurvey = (survey) => {
    const _isAnAssociationField = fieldName => _.endsWith(fieldName, '_attributes')

    survey.css = SjsUtils.getCustomizedBootstrapCss()

    survey.onValueChanged.add((survey, options) => {
      console.debug(`form changed at ${options.name}`)
      const empties = attrs.reduce((acc, key) => ({ ...acc, [key]: null }), {})
      updateData({ ...empties, ...survey.data })
    })

    survey.onUpdateQuestionCssClasses.add(function (survey, options) {
      options.cssClasses.mainRoot += ` cgp_qstn_${options.question.getType()} ${options.question.rootClassSuffix || ''}`
    })
    survey.onUpdatePanelCssClasses.add(function (survey, options) {
      options.cssClasses.panel.container += ` ${options.panel.rootClassSuffix || ''}`
    })
    survey.onTextMarkdown.add(markdownHandler)

    // callback to prevent sjs from deleting the associaton id (because it is invisible) when an association get updated (https://github.com/surveyjs/surveyjs/issues/1542)
    survey.onValueChanging.add((sender, options) => {
      if (_isAnAssociationField(options.name)) { // a change happened in the associations nested attributes
        if (options.value && options.oldValue && options.oldValue.length == options.value.length) { // it's not an addition or deletion: its an association update
          _.forEach(options.oldValue, (row, index) => {
            if (row.id) { // keep the old id if it exists
              options.value[index].id = row.id
            }
          })
        }
      }
      // console.debug(`form changing at ${options.name}`)
      // console.debug(options.oldValue)
      // console.debug(options.value)
    })
  }

  SjsUtils.setupForResourceForm(SurveyCore)
  const survey = buildSurvey()

  let errorsAlert = []
  if (!_.isEmpty(errors)) {
    errorsAlert = (
      <Alert variant="danger" className="mb-0 mt-2">
        <Alert.Heading>
          Errors found:
        </Alert.Heading>
        <ul>
          {errors.map(e => <li key={e}>{e}</li>)}
        </ul>
      </Alert>
    )
  }

  return (
    <div>
      {errorsAlert}
      <div className="form-inputs">
        <Survey
          data={extractSjsData(attrs)}
          model={survey}
        />
      </div>
    </div>
  )
}

StepForm.propTypes = {
  attrs: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
}

export default StepForm
