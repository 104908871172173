import * as Routes from "../routes.js"
window.Routes = Routes

import {mountReactComponent} from "../components/utils"
import PersonCohortsLister from "../components/person_cohorts_lister"
import IdWidget from "../components/id_widget"
import AddPerson from "../components/add_person"
import CohortMembersLister from "../components/cohort_members_lister"
import PersonIdsManager from "../components/person_ids_management/person_ids_manager"
import ResourceForm from "../components/resource_form/resource_form"
import CollectorForm from "../components/resource_form/collector_form"
import ResourcesLister from "../components/resources_lister/resources_lister"
import UsersLister from "../components/users_lister"
import RelativesEditor from "../components/relatives_editor/relatives_editor"
import SamplesManager from "../components/samples_manager/app"
import ProjectParticipantsLister from "../components/project_participants_lister"
import CollectorContent from "../components/collector_content/collector_content"
import QuestionnaireContent from "../components/collector_content/questionnaire_content"
import ProjectDataVisualizer from "../components/project_data/visualizer/app"
import CollectorLegendWidget from "../components/collector_legend_widget"
import SelectLocaleWidget from "../components/select_locale_widget"
import SessionWarnWidget from "../components/session_warn_widget"
// import ProjectDataImporter from "../components/project_data/importer/app"

document.addEventListener("DOMContentLoaded", () => {
  mountReactComponent(PersonCohortsLister, "PersonCohortsLister")
  mountReactComponent(IdWidget, "IdWidget")
  mountReactComponent(AddPerson, "AddPerson")
  mountReactComponent(CohortMembersLister, "CohortMembersLister")
  mountReactComponent(PersonIdsManager, "PersonIdsManager")
  mountReactComponent(ResourceForm, "ResourceForm")
  mountReactComponent(CollectorForm, "CollectorForm")
  mountReactComponent(ResourcesLister, "ResourcesLister")
  mountReactComponent(UsersLister, "UsersLister")
  mountReactComponent(RelativesEditor, "RelativesEditor")
  mountReactComponent(SamplesManager, "SamplesManager")
  mountReactComponent(ProjectParticipantsLister, "ProjectParticipantsLister")
  mountReactComponent(CollectorContent, "CollectorContent")
  mountReactComponent(QuestionnaireContent, "QuestionnaireContent")
  mountReactComponent(ProjectDataVisualizer, "ProjectDataVisualizer")
  mountReactComponent(CollectorLegendWidget, "CollectorLegendWidget")
  mountReactComponent(SelectLocaleWidget, "SelectLocaleWidget")
  mountReactComponent(SessionWarnWidget, "SessionWarnWidget")
  // mountReactComponent(ProjectDataImporter, "ProjectDataImporter")
})
