import React, {useState, useEffect, useCallback} from "react"
import PropTypes from "prop-types"
import CheckboxTree from "react-checkbox-tree"
import {Row, Col, Tooltip, Form, OverlayTrigger, InputGroup, FormControl, Button} from "react-bootstrap"
import styled from "styled-components"
import LiftUpHistory from "./lift_up_history"
import LoadingOverlay from "react-loading-overlay"
LoadingOverlay.propTypes = undefined // workaround to remove warning https://github.com/derrickpelletier/react-loading-overlay/pull/57#issuecomment-1054194254

function CheckBoxLabel(props) {
  const displayName = props.titleMode ? props.title : props.name
  const tooltip =
    <Tooltip id="tooltip1" className={props.title == props.name && "hidden"}>
      {props.titleMode ? props.name : props.title}
    </Tooltip>

  return <OverlayTrigger placement="top" overlay={tooltip}>
    <span>
      {/^array_of_/.test(props.data_type) ? `[${displayName}]` : displayName}
      {
        props.is_key ? <sup className="text-danger">KEY</sup> : ""
      }
    </span>
  </OverlayTrigger>


}

CheckBoxLabel.propTypes = {
  data_type: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  note: PropTypes.string,
  value_name: PropTypes.string,
  id: PropTypes.string.isRequired,
  is_key: PropTypes.bool,
  titleMode: PropTypes.bool
}

// need to be placed outside the function Component otherwise it will be redefined on every run cousing a complete remount
// avoid flat checkboxes indentation
const Styles = styled.div`
.react-checkbox-tree {
  line-height: 1.3;
  &> ol > .rct-node:not(.rct-node-parent) > .rct-text > span.rct-collapse {
    display: none;
  }
  label {
    font-weight: 400;
  }
}
`

function Selector(props) {

  // console.debug("rendering Selector")

  const [disabledNodes, setDisabledNodes] = useState({})
  const [filteredNodes, setFilteredNodes] = useState({})
  const [titleMode, setTitleMode] = useState(false)
  const [isFiltering, setIsFiltering] = useState(false)
  const [filter, setFilter] = useState("")

  const getInvolvedNodes = (cId, checked) => {
    console.debug(`recalculating involved nodes in collector ${cId}`)
    checked ||= (props.checkedNodes[cId] || [])

    if (!checked.length) return

    let involvedNodes = new Set()

    // add fields involved in liftUp history
    const liftUpHistory = new LiftUpHistory(props.liftupHistoriesData[cId])
    _.eachDeep(props.collectors[cId], e => {
      if (
        liftUpHistory.involvedDataPaths.length &&
        e.data_path.length &&
        (e.data_type == "scalar" || e.data_type == "array_of_scalars") &&
        _.some(liftUpHistory.involvedDataPaths,n=>_.startsWith(n,e.data_path))
      )
        involvedNodes.add(e.id)
    }, {childrenPath: "children"})

    checked = _.union(checked,Array.from(involvedNodes))

    // add all involved ancestors key fields
    _.eachDeep(props.collectors[cId], e => {
      const keyChild = _.find(e.children, "is_key")
      if (keyChild && checked?.find(j => _.startsWith(j,e.id) && j != keyChild.id)) {
        involvedNodes.add(keyChild.id)
      }
    }, {childrenPath: "children"})

    return Array.from(involvedNodes)
  }

  const getFilteredNodes = (cId) => {
    console.debug(`recalculating filtered nodes in collector ${cId}`)

    // if (filter.length <= 2) return

    let filteredSet = new Set()
    _.filterDeep(props.collectors[cId], (value, key, parentValue, context) => {
      if (_.isPlainObject(value)) {
        if ( value.type == "root" || (value.name?.toLowerCase().match(filter.toLowerCase()) ||
          (value.title && !_.isPlainObject(value.title) && value.title.toLowerCase().match(filter.toLowerCase())) )) {
          filteredSet.add(value.id)
        } else
          filteredSet.delete(value.id)
      }

    }, {childrenPath: "children"})

    // set add all involved ancestors visibility
    _.eachDeep(props.collectors[cId], (value, key, parentValue, context) => {
      if (value.data_type != "scalar" && [...filteredSet].find(nodeId => _.startsWith(nodeId, value.id))) {
        filteredSet.add(value.id)
      }
    }, {childrenPath: "children"})

    return [...filteredSet]
  }

  const checkBoxProps = ["id","name","data_type","title","description", "note","children","is_key"]
  let collectors = _.mapValues(props.collectors, (v,cId) => {
    // console.log(_.pick( v, checkBoxProps))
    let obj = _.pickDeep( v, checkBoxProps)
    return _.mapValuesDeep( obj, (value, key, parentValue, context) => {
      const legendButton = !parentValue ?
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip2">Show Legend</Tooltip>}>
            <Button className="ml-1" size="xs" variant="link" onClick={e=> props.handleShowLegend(cId)}>
              <i className="fa fa-list"/>
            </Button>
          </OverlayTrigger>
        :
          null

      const tagsList = !parentValue ?
          <span className='ml-1'>
            {
              props.collectorTags[cId].length
                ? props.collectorTags[cId].map(tag => (
                  <span className="badge badge-light" key={tag}>
                    {tag}
                  </span>
                ))
                : null
            }
          </span>
        :
          null

      if (_.isPlainObject(value)) {
        return {
          value: value.id,
          children: value.children,
          label: <>
            <CheckBoxLabel {..._.pick(value, checkBoxProps)} titleMode={titleMode} />
            {tagsList}
            {legendButton}
          </>,
          disabled: disabledNodes[cId]?.includes(value.id),
          className: !!filteredNodes[cId] && !filteredNodes[cId].includes(value.id) ? "d-none" : ""
        }
      } else {
        return value
      }
    })
  })


  useEffect(() => {
    setDisabledNodes(_.mapValues(props.collectors, (c,cId) => getInvolvedNodes(cId)))
  }, [props.liftupHistoriesData, props.checkedNodes])
  useEffect(() => {
    setFilteredNodes(_.mapValues(props.collectors, (c,cId) => getFilteredNodes(cId)))
  }, [filter])


  const handleChangeFilterText = _.debounce(text => {
    setFilter(text)
    setIsFiltering(false)
  }, 1000)

  const debouncedHandleChangeFilterText = useCallback(handleChangeFilterText,[])

  let warnMessage
  if (filter) {
    const tooltip =
     <Tooltip>
       Filtering is on. Clear filter to see all fields.
     </Tooltip>

    warnMessage = <OverlayTrigger placement="right" overlay={tooltip}>
      <i className="fa fa-exclamation-triangle text-warning"/>
    </OverlayTrigger>
  }


  return  <Styles>
    <Row className="mb-3">
      <Col lg={6}>
        <h5 className="font-weight-bold">Collector fields</h5>
        <Form className="mb-2" inline>
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Type to filter fields..."
              onChange={e => {
                debouncedHandleChangeFilterText(e.target.value)
                setIsFiltering(true)
              }}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <i className="fas fa-filter"/>
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          <Form.Check className="ml-2 mr-2">
            <Form.Check.Label>
              <Form.Check.Input
                type="checkbox"
                checked={titleMode}
                onChange={e => setTitleMode(e.target.checked)}
              />
              Show titles
            </Form.Check.Label>
          </Form.Check>
          {warnMessage}
        </Form>
        <LoadingOverlay
          active={isFiltering}
          text='Loading ...'
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.3)"
            })
          }}
        >
          {_.map(collectors, (cData, cId) =>
            <div key={cId} className='mt-1'>
              <CheckboxTree
                key={cId}
                checkModel="all"
                nodes={[cData]}
                checked={props.checkedNodes[cId]}
                expanded={props.expandedNodes[cId]}
                onCheck={(checked, target) => {
                  if (!target.checked) { // when uncheck a node also force uncheck of its children disabled nodes
                    checked = target.treeDepth ? _.filter(checked,v=>!_.startsWith(v,`${target.value}.`)) : []
                  }
                  // add involved nodes to checked nodes
                  props.handleCollectorCheckboxChange(cId, _.union(checked, getInvolvedNodes(cId,checked)))
                }}
                onExpand={expanded => props.handleCollectorCheckboxExpand(cId, expanded)}
                showNodeIcon={false}
                disabled={props.disabled || props.collectorsLoadingStatus[cId]}
                expandDisabled={props.disabled}
                showExpandAll={true}
                nativeCheckboxes={true}
                iconsClass="fa5"
              />
            </div>
          )}
        </LoadingOverlay>
      </Col>
      <Col lg={4}>
        <h5 className="font-weight-bold">
          Cohorts <small>
            <Button className="p-1" variant="link" disabled={props.cohorts.length == props.checkedCohorts.length} onClick={e=> props.handleCohortCheckboxChange(_.map(props.cohorts,'value'))}>
              all
            </Button>
            /
            <Button className="p-1" variant="link" disabled={_.isEmpty(props.checkedCohorts)} onClick={()=> props.handleCohortCheckboxChange([])}>
              none
            </Button>
          </small>
        </h5>
        <CheckboxTree
          nodes={props.cohorts}
          checked={props.checkedCohorts}
          onCheck={(checked, target) => props.handleCohortCheckboxChange(checked)}
          showNodeIcon={false}
          nativeCheckboxes={true}
          expandDisabled={true}
          disabled={props.disabled || _.some(props.collectorsLoadingStatus) }
          iconsClass="fa5"
        />
      </Col>
      <Col lg={2}>
        <h5 className="font-weight-bold">IDs <small>
            <Button className="p-1" variant="link" disabled={props.ids.length == props.checkedIds.length} onClick={e=> props.handleIdCheckboxChange(props.ids)}>
              all
            </Button>
          </small>
        </h5>
        <CheckboxTree
          nodes={props.ids.map(id => { return {label: id, value: id, disabled: id == props.personIdName} } )}
          checked={props.checkedIds}
          onCheck={(checked, target) => props.handleIdCheckboxChange(checked)}
          showNodeIcon={false}
          nativeCheckboxes={true}
          disabled={props.disabled || _.some(props.collectorsLoadingStatus) }
          iconsClass="fa5"
        />
      </Col>
    </Row>
  </Styles>
}

Selector.propTypes = {
  projectId: PropTypes.number.isRequired,
  cohorts: PropTypes.array.isRequired,
  ids: PropTypes.array.isRequired,
  personIdName: PropTypes.string.isRequired,
  collectors: PropTypes.object.isRequired,
  handleShowLegend: PropTypes.func.isRequired,
  collectorsLoadingStatus: PropTypes.object.isRequired,
  checkedNodes: PropTypes.object.isRequired,
  expandedNodes: PropTypes.object.isRequired,
  liftupHistoriesData: PropTypes.object,
  checkedCohorts: PropTypes.array.isRequired,
  checkedIds: PropTypes.array.isRequired,
  handleCollectorCheckboxChange: PropTypes.func.isRequired,
  handleCohortCheckboxChange: PropTypes.func.isRequired,
  handleIdCheckboxChange: PropTypes.func.isRequired,
  handleCollectorCheckboxExpand: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  collectorTags: PropTypes.array
}

Selector.defaultProps = {
  liftupHistoriesData: {}
}

export default Selector

