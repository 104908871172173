import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import Papa from 'papaparse'
import JSZip from 'jszip'

const exportExcel = ({data, filename, selectedRowIds, personIdName}) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  let outData, wb = { Sheets: {}, SheetNames: [] }

  const addSheet = (sheet) => {
    const
      filteredValues = sheet.values.filter(v => selectedRowIds[v[personIdName]]),
      fieldNames = _.map(_.filter(sheet.fields, f => !f.hidden), 'name')

    wb.Sheets[sheet.excelName] =  XLSX.utils.aoa_to_sheet([fieldNames].concat(filteredValues ? filteredValues.map(row => fieldNames.map(k => row[k])) : []))
    wb.SheetNames.push(sheet.excelName)
  }

  if (_.isPlainObject(data)) {
    addSheet(data)
    filename = data.excelName
  } else {
    _.forEach(data, sheet => addSheet(sheet))
  }

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  outData = new Blob([excelBuffer], {type: fileType})
  FileSaver.saveAs(outData, filename + ".xlsx")
}

const exportCSV = ({ data, filename, selectedRowIds, personIdName }) => {
  const fileType = 'text/csv;charset=UTF-8'

  const prepareFile = (data) => {
    const filteredValues = data.values.filter(v => selectedRowIds[v[personIdName]])
    const fieldNames = _.map(_.filter(data.fields, f => !f.hidden), 'name')
    const csv = Papa.unparse(filteredValues, { columns: fieldNames })
    if (csv == null) return
    return new Blob([csv], { type: fileType })
  }

  if (_.isPlainObject(data)) {
    FileSaver.saveAs(prepareFile(data), data.name + '.csv')
  }
  else {
    const zip = new JSZip()
    _.forEach(data, (sheet) => {
      zip.file(sheet.name + '.csv', prepareFile(sheet))
    })
    zip.generateAsync({ type: 'blob' })
      .then(function (blob) {
        FileSaver.saveAs(blob, filename + '.zip')
      })
  }
}

const exportHtml = ({ bodyContent, style, filename, title }) => {
  const
    fileType = 'text/html',
    fileContent = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <style type="text/css" media="screen">
      ${style}
    </style>
    <title>${title}</title>
  </head>
  <body>
    ${bodyContent}
  </body>
</html>`,
    outData = new Blob([fileContent], { type: fileType })

  FileSaver.saveAs(outData, (filename || _.snakeCase(title)) + '.html')
}

const exportLegendAsCsv = ({ columns, data, filename }) => {
  const fileType = 'text/csv;charset=UTF-8'
  const prepareFile = (data) => {
    const csv = Papa.unparse({ data: data, fields: columns, delimiter: ';' })
    if (csv == null) return
    return new Blob([csv], { type: fileType })
  }
  FileSaver.saveAs(prepareFile(data), filename + '.csv')
}

export { exportHtml, exportExcel, exportCSV, exportLegendAsCsv }
