import React from 'react'
import PropTypes from 'prop-types'
import * as SurveyCore from 'survey-core'
import { Survey } from 'survey-react-ui'
import addWidgets from '../../sjs_extensions/widgets'
import * as SurveyCustomFunctions from '../../sjs_extensions/custom_functions'
import * as SjsUtils from '../../sjs_extensions/sjs_utils'
import { markdownHandler, addDeletionsToDataForRails } from '../utils'
import { RendererFactory } from 'survey-core'

export default class Form extends React.Component {
  static propTypes = {
    model: PropTypes.object.isRequired,
    data: PropTypes.object,
    originalData: PropTypes.object,
    externalCallbacks: PropTypes.object,
    onlyRead: PropTypes.bool,
    surveyAction: PropTypes.string,
    errors: PropTypes.array,
    handleChange: PropTypes.func,
    handleSave: PropTypes.func,
  }

  static defaultProps = {
    onlyRead: false,
    data: {},
    originalData: {},
    externalCallbacks: {},
  }

  constructor(props) {
    super(props)
    this.resetSurvey(this.props.model)
  }

  updateSurveyAfterAction = () => {
    switch (this.props.surveyAction) {
      case 'complete':
        this.survey.doComplete()
        break
      case 'reset':
      // this.resetSurvey(this.props.model)
        break
      default:
    }
  }

  resetSurvey = (model) => {
    SjsUtils.setupForResourceForm(SurveyCore)

    const baseSjsModel = {
      clearInvisibleValues: 'onHidden',
      requiredText: '* ',
      showNavigationButtons: 'none',
      showCompletedPage: false,
      showQuestionNumbers: 'off',
      checkErrorsMode: 'onValueChanged',
      mode: this.props.onlyRead ? 'display' : 'edit',
      questionDescriptionLocation: 'underInput',
    }

    const survey = new SurveyCore.Model(_.merge(baseSjsModel, model))

    this.addCallbacks(survey)

    this.survey = survey
  }

  addCallbacks = (survey) => {
    // default behaviour
    survey.onValueChanged.add(this.valueChanged)
    survey.onComplete.add(this.completed)

    survey.onUpdateQuestionCssClasses.add(function (survey, options) {
      options.cssClasses.mainRoot += ` cgp_qstn_${options.question.getType()} ${options.question.rootClassSuffix || ''}`
    })
    survey.onUpdatePanelCssClasses.add(function (survey, options) {
      options.cssClasses.panel.container += ` ${options.panel.rootClassSuffix || ''}`
    })
    survey.onTextMarkdown.add(markdownHandler)

    // callback to prevent sjs from deleting the associaton id (because it is invisible) when an association get updated (https://github.com/surveyjs/surveyjs/issues/1542)
    survey.onValueChanging.add((sender, options) => {
      if (this._isAnAssociationField(options.name)) { // a change happened in the associations nested attributes
        if (options.value && options.oldValue && options.oldValue.length == options.value.length) { // it's not an addition or deletion: its an association update
          _.forEach(options.oldValue, (row, index) => {
            if (row.id) { // keep the old id if it exists
              options.value[index].id = row.id
            }
          })
        }
        // console.debug('DATA CHANGE FROM:');
        // console.debug(options.oldValue);
        // console.debug('TO');
        // console.debug(options.value);
      }
    })
  }

  valueChanged = (survey, options) => {
    console.debug(`form changed at ${options.name}`)
    this.props.handleChange(this.survey.data)
  }

  // https://stackoverflow.com/questions/36691125/lodash-isblank
  _isBlankField(value) {
    return _.isEmpty(value) && !_.isNumber(value) || _.isNaN(value)
  }

  _isAnAssociationField(fieldName) {
    return _.endsWith(fieldName, '_attributes')
  }

  completed = (survey) => {
    survey.clear(false, false)
    if (!survey.checkIsCurrentPageHasErrors()) {
      this.props.handleSave(addDeletionsToDataForRails(this.survey.data, this.props.originalData))
    }
  }

  render() {
    console.debug('RERENDERING FORM')

    this.updateSurveyAfterAction()

    let errors = []
    if (this.props.errors.length) {
      errors = (
        <div className="alert alert-danger alert-dismissible">
          <button className="close" data-dismiss="alert">x</button>
          <h5>
            <i className="icon fa fa-ban" />
            Errors:
          </h5>
          <ul>
            {this.props.errors.map((error, i) => <li key={i}>{error}</li>)}
          </ul>
        </div>
      )
    }
    return (
      <div>
        {errors}
        <div className="form-inputs">
          <Survey
            css={SjsUtils.getCustomizedBootstrapCss()}
            data={this.props.data}
            model={this.survey}
          />
        </div>
      </div>
    )
  }
}
