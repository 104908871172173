function init(SurveyCore, $) {
  $ = $ || window.$

  function isValidPartialDate(value) {
    const date = value?.toString()
    if (!date)
      return
    let parsedDate = moment(date, true) // strict mode enabled: only ISO8601 date are valid
    // TODO: add check on from date
    return parsedDate.isValid()
  }

  class PartialDateValidator extends SurveyCore.TextValidator {
    constructor() {
      super()
    }

    getType() {
      return 'partialdatevalidator'
    }

    get text() {
      return 'Please enter a valid partial date in the format of YYYY, YYYY-MM or YYYY-MM-DD'
    }

    validate(value, name) {
      if (!value || isValidPartialDate(value)) {
        return null
      }
      else {
        return new SurveyCore.ValidatorResult(value, this.createCustomError())
      }
    }
  }

  var widget = {
    /**
     * Required attribute. Unique name in lower case.
     */
    name: 'partialdate',
    /**
     * Optional attribute. Toolbox use this value to display it as a text in toolbox item.
     * If it is empty, then name is used.
     */
    title: "Partial Date",
    /**
     * Optional attribute. If Toolbox has several categories then
     * this attribute defines to which category this custom widget belongs.
     */
    category: '',
    /**
     * Optional attribute. Toolbox use it to show the icon type in toolbox item.
     * If it is empty, then Creator uses "icon-default" value.
     */
    iconName: '',
    /**
     * This function is required if you are going to introduce a new question type
     * and register it on SurveyJS Creator toolbox.
     * It should return true, when all needed resources (javascript and css files) are loaded
     */
    widgetIsLoaded: function () {
      return true
    },
    /**
     * This function returns true when we decided to apply our widget to the question.
     * This function is required.
     * SurveyJS Library allows to apply only one custom widget to a question.
     * If there are several custom widgets that can be applied to the same question,
     * then the first custom widget in the custom widget collection wins.
     */
    isFit: function (question) {
      let isFit = question.getType() === 'text' && question.inputType === 'partialdate'
      if (isFit) {
        question.validators.push(new PartialDateValidator())
      }
      return isFit
    },
    /**
     * SurveyJS calls this function one time on registing the custom widget.
     * This function is optional
     */
    init: function() {
    //Add/modify/remove classes and properties
      SurveyCore.Serializer.findProperty("text","inputType").choicesValue.push("partialdate")
    },
    /**
     * If you want to have the default input rendering for the existing question, then set this property to true.
     */
    isDefaultRender: true,
    /**
       * SurveyJS will render this template for question input if this property is not empty.
       */
    // htmlTemplate: "<input class='custom_class' />",
    /**
     * You have to put here code to modify the DOM, using the html element as a root element.
     * In case of creating your own question type,
     * you have to create data binding between your widget and question value.
     * If needed, react on changing read-only question state.
     */
    // afterRender: function(question, element) {
    // }
  }

  SurveyCore.CustomWidgetCollection.Instance.addCustomWidget(widget, 'customtype')
}

export default init
