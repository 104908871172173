import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Alert, OverlayTrigger, Tooltip, ToggleButtonGroup, ToggleButton, Dropdown, DropdownButton, Card, ButtonToolbar, Row, Col, Container } from "react-bootstrap"
import { exportExcel, exportCSV } from "../utils"
import DataTable from "./datatable"
import LiftUpManager from "./lift_up_manager"
import styled from "styled-components"
import TreeMenu, { ItemComponent } from "react-simple-tree-menu"
import LiftUpHistory from "./lift_up_history"
import DatabaseWidget from "./database_widget"

// need to be placed outside the function Component otherwise it will be redefined on every run cousing a complete remount
const Styles = styled.div`
  .card {
    .card-header {
      padding: 0.5rem;
    }
    .card-body {
      padding: 0.5rem;
    }
    .card-footer {
      padding: 0.5rem;
    }
  }
`

function Visualizer(props) {

  // console.debug(`render visulizer ${props.model.id}`)

  const [selectedSheetId, setSelectedSheetId] = useState("main")
  const [sheetsNavMode, setSheetsNavMode] = useState("path")

  const liftUpHistory = new LiftUpHistory(props.liftUpHistoryData)

  let workbook = useMemo(
    () => {
      let wb = _.cloneDeep(props.workbook)

      console.debug(`Visualizer ${props.model.id}: keep only sheets involved in node selection`)
      // keep only sheets involved in node selection
      wb = _.pickBy(wb, sheet => sheet.id == "main" || _.some(props.nodeIds, nodeId => _.startsWith(nodeId,sheet.nodeId) ))

      // fallback to main sheet when selected sheet is not present in workbook anymore
      if (!wb[selectedSheetId]) {
        setSelectedSheetId("main")
      }

      console.debug(`Visualizer ${props.model.id}: integrate workbook with ids and ancestor keys fields`)
      _.each(wb, (sheet,sheetId) => {

        sheet.parent = wb[sheet.parentId]
        sheet.children = _.values(_.pick(wb,...sheet.childIds))
        sheet.ancestors = function() {
          return this.parent ? this.parent.ancestors().concat([this.parent]) : []
        }

        if (sheet.isMain) {
          sheet.idName = props.ids[0]
          sheet.foreignIdName = props.ids[0]
        }

        // remove unselected collector nodes
        _.remove(sheet.fields, f => !_.includes(props.nodeIds, f.id))

        // before adding derived fields mark all original fields
        _.each(sheet.fields, f => f.type = 'orig')

        let auxFields = []

        // add ids
        _.each(props.ids,(id,i) => {
          if (i==0) { // add pid which must be present, at least hidden
            auxFields.push({
              id: id,
              name: id,
              contentType: "string",
              type: "pId",
              hidden: !(sheet.isMain || props.options.showPersonIdsInAllSheets)
            })
          } else { // add all other person ids
            if (sheet.isMain || props.options.showPersonIdsInAllSheets) {
              auxFields.push({
                id: id,
                name: id,
                contentType: "string",
                type: "pId"
              })

            }
          }
        })

        if (!sheet.isMain) {
          // add ancestors foreign keys
          _.each(sheet.ancestors(), (a,i) => {
            if (a==sheet.parent || props.options.showAllAncestorsKeys) { // parent must be present (liftUp application is based on the parent node )
              if (a.isMain) { // ancestor main is just unhide
                auxFields[0].hidden = false
              } else {
                auxFields.push({
                  id: a.foreignKeyName,
                  name: a.foreignKeyName,
                  contentType: "string",
                  type: "fKey",
                  hidden: props.options.showSequentialIds && !props.options.showAllAncestorsKeys
                })
              }
            }
          })

          // add sequential additional ids (liftUp use them to aggregate)
          // sequential foreign key to parent
          if (!sheet.parent.isMain) {
            auxFields.push({
              id: sheet.parent.foreignIdName,
              name: sheet.parent.foreignIdName,
              contentType: "integer",
              type: "sfKey",
              hidden: !props.options.showSequentialIds
            })
          }
          // sequential primary key
          auxFields.push({
            id: sheet.idName,
            name: sheet.idName,
            contentType: "integer",
            type: "spKey",
            hidden: !props.options.showSequentialIds
          })

        }

        sheet.fields.unshift(...auxFields)

      })
      return wb
    },[
      props.nodeIds,
      props.ids,
      props.options
    ]
  )

  // integrate contents and assign values to workbook
  useMemo(
    () => {
      console.debug(`Visualizer ${props.model.id}: integrate contents with info related to ids and ancestors`)

      // console.time("wholeLoop")
      const integratedContents = _.map(props.contents, content => {

        // console.time("contentLoop")
        let personIdValues = Object.assign({}, ...props.ids.map(id => ({[id]: content[id]})))
        let integratedContent = _.cloneDeep(content)

        let res = _.reduceDeep(integratedContent, (accumulator, value, key, parentValue, context) => {

          // element belonging to array needs to be integrated with info related to ancestors
          if (_.isArray(parentValue)) {

            // here we need to guess the sheetId from current data value
            // removing all array indexes like "tumors[0].chemotherapies[3].treatments"
            // and chars []" in case of path starting with numbers like '["3elr_postoperativeComplications"]'
            let sheetId = context.parent.path.replaceAll(/\[\d+\]/g,'').replaceAll(/[\[\]"]/g,''),
              sheet = workbook[sheetId]

            if (sheet) {
              let newValue = _.cloneDeep(value)

              // if it is an array of scalar the sheet is integrated with value filed
              if (!_.isPlainObject(value)) {
                newValue = { value: value }
              }

              // set all ancestor key values
              _.forEach(sheet.ancestors(), currentAncestor => {
                if (currentAncestor.isMain) return // skip main sheet foreign key as it's  always added by adding personIdValues
                let currentAncestorValue = context.parents.find(a => a.parent && a.parent.key == currentAncestor.name).value
                newValue[currentAncestor.foreignKeyName] = currentAncestorValue[currentAncestor.keyName]
              })

              sheet.counter ? sheet.counter += 1 : sheet.counter = 1

              // set sequential row id
              newValue[sheet.idName] = sheet.counter

              // set parent row id
              if (!sheet.parent.isMain) {
                newValue[sheet.parent.foreignIdName] = sheet.parent.counter
              }

              // integrate with personIdValues
              _.merge(newValue, personIdValues)
              _.set(accumulator, context.path, newValue)
            }
          }

          return accumulator
        }, integratedContent, {includeRoot: false})
        // console.timeEnd("contentLoop")
        return res
      })
      // console.timeEnd("wholeLoop")
      workbook.main.counter = integratedContents.length

      console.debug(`Visualizer ${props.model.id}: add values to workbook`)
      _.each(workbook, (sheet,sheetId) => {

        if (integratedContents.length) {

          // clone data
          let sheetContents = _.cloneDeep(integratedContents)

          // select current sheet contents
          let path = sheetId.split(".")
          do {
            let currentPath = path.shift()
            if (currentPath == "main") { break }
            sheetContents = _.flatten(_.compact(_.map(sheetContents, c => c[currentPath])))
          } while (path.length)

          // select only fields involved by the current sheet
          // console.time("wholeLoop")
          sheet.values = _.map(sheetContents, c => _.reduce(sheet.fields, (memo, f) => {
            memo[f.name] = _.get(c,f.name)
            if (_.isArray(memo[f.name])) {
              memo[f.name] = memo[f.name].join(", ")
              console.warn(`An unexpected array was found in field ${f.name} in sheet ${sheet.id}. It's elements will be joined for visualization in sheet cell` )
            }
            return memo
          },{}))
          // console.timeEnd("wholeLoop")

          // consolidate columns types
          sheet.fields = _.map(sheet.fields, f => {
            const values = _.without(_.map(sheet.values, v => v[f.name]),"",undefined,null,NaN),
              detectedTypes = _.uniq(values.map(v=>typeof(v))),
              declaredType = f.contentType

            let resultingType

            switch (detectedTypes.length) {
            case 0:
              console.warn(`Cannot detect type for column ${f.name} in sheet ${sheet.id}: no data found. DeclaredType (${declaredType}) will be used` )
              resultingType = declaredType == 'number' ? 'integer' :  declaredType // fallback to the type coming from the sjs model
              break
            case 1:
              if (detectedTypes[0] == 'number') {
                resultingType = _.some(values, v => !_.isInteger(v)) ? 'float' : 'integer'
              } else {
                resultingType = detectedTypes[0]
              }
              break
            default:
              console.warn(`Cannot determine type for column ${f.name} in sheet ${sheet.id}: different types detected (${detectedTypes.join(",")})` )
              resultingType = undefined
              break
            }

            if (resultingType == "object") { // this also includes array because typeof([]) return object
              console.warn(`Detected type is not a scalar (string,number,boolean) for column ${f.name} in sheet ${sheet.id}`)
            }

            if (resultingType && declaredType && declaredType != resultingType) {
              console.warn(`Detected type (${resultingType}) is different from declared type (${declaredType}) for column ${f.name} in sheet ${sheet.id}`)
            }

            f.contentType = resultingType

            if (values.length) {
              switch (resultingType) {
                case 'integer':
                  f.maxVal = Math.max(...values)
                  break
                case 'float':
                  f.maxVal = Math.max(...values)
                  break
                case 'string':
                  f.maxLength = Math.max(...(values.map(el => el.length)))
                  break
              }
            }

            return f
          })
        } else {
          sheet.values = []
        }

      })


    },[
      workbook,
      props.contents
    ]
  )

  // apply liftup history to workbook
  useMemo(
    () => {
      console.debug(`Visualizer ${props.model.id}: applying liftup history`)
      // remove all liftedUp fields
      _.values(workbook).forEach(sheet => _.remove(sheet.fields, f => f.type == "liftedUp"))
      if (workbook.main.counter) {
        // rebuild all liftedUp fields
        _.each(liftUpHistory.steps,(step, stepIdx) => {
          let
            sheet=workbook[step.sheetId],
            sourceField

          // organize parent values by id
          const parentSheetValuesById = _.keyBy(sheet.parent.values, sheet.parent.idName)
          const sheetValuesByParentId = _.groupBy(sheet.values, v => v[sheet.parent.foreignIdName])

          let insertAt = step.afterField ?
            sheet.parent.fields.findIndex(f=>f.name == step.afterField) + 1
            :
            sheet.parent.fields.length

          let contentType

          switch(step.type) {
          case "agg":

            sourceField = _.find(sheet.fields, f => f.name == step.field)
            contentType = sourceField.contentType
            _.each(sheetValuesByParentId, (items,id) => {
              const values = _.compact(_.map(items,step.field))
              if (_.isEmpty(values)) {
                return
              }
              switch (step.formula) {
              case "concat":
                parentSheetValuesById[id][step.name] = (sheet.isMadeOfScalars ? values.sort() : values).join(" | ") // sort values in case of scalars
                contentType = "string"
                break
              case "count":
                parentSheetValuesById[id][step.name] = values.length
                contentType = "integer"
                break
              case "sum":
                parentSheetValuesById[id][step.name] = _.sum(values)
                break
              case "min":
                parentSheetValuesById[id][step.name] = _.min(values)
                break
              case "max":
                parentSheetValuesById[id][step.name] = _.max(values)
                break
              case "avg":
                parentSheetValuesById[id][step.name] = _.mean(values)
                contentType = "float"
                break
              }
            })
            sheet.parent.fields.splice(insertAt,0,{
              name: step.name,
              contentType: contentType,
              type: "liftedUp",
              liftedUpStep: stepIdx
            })
            break
          case "exp":
            _.times(_.max(_.map(_.values(sheetValuesByParentId), "length")), i =>
              _.each(step.fields, fieldName => {
                const name = `${step.prefix}${sheet.isMadeOfScalars ? "" : fieldName.concat("_")}${i+1}`
                sourceField = _.find(sheet.fields, f => f.name == fieldName)
                sheet.parent.fields.splice(insertAt,0,{
                  name: name,
                  contentType: sourceField.contentType,
                  type: "liftedUp",
                  liftedUpStep: stepIdx
                })
                insertAt += 1
              })
            )
            _.each(sheetValuesByParentId, (items,id) => {
              _.each(sheet.isMadeOfScalars ? _.sortBy(items,'value') : items, (item,i) => {  // sort values in case of scalars
                _.each(step.fields, f => {
                  const name = `${step.prefix}${sheet.isMadeOfScalars ? "" : f.concat("_")}${i+1}`
                  parentSheetValuesById[id][name] = item[f] // do not compress in one line code because item[step.field] may be false in case of boolean field and break _.each loop
                })
              })
            })
            break
          }
        })
      }

    },[
      workbook,
      props.contents,
      props.liftUpHistoryData
    ]
  )

  // update counters
  useMemo(
    () => {
      console.debug(`Visualizer ${props.model.id}: update selected counters`)
      _.each(_.keys(workbook), sheetId => workbook[sheetId].selectedCounter = props.selectedRowIds ? _.filter(workbook[sheetId].values, v => props.selectedRowIds[v[props.ids[0]]]).length : workbook[sheetId].counter)
    },[
      props.selectedRowIds,
      workbook,
      props.contents
    ]
  )


  // eslint-disable-next-line no-console
  console.log("->",workbook)

  const exportParams = {
    collector_id: props.collectorId,
    node_ids: props.nodeIds,
    ids: props.ids,
    options: props.options,
    cohort_ids: props.checkedCohorts,
    selected_row_ids: props.selectedRowIds,
    liftup_history_data: props.liftUpHistoryData
  }

  // useMemo(
  //   () => {
  //     setIsLoading(true)
  //     xFetch(Routes.project_data_visualizer_workbook_path(props.projectId, exportParams))
  //       .then(data => {
  //         let wb = data.result
  //         console.debug(`Visualizer ${props.model.id}: integrate workbook`)
  //         _.mapValues(wb, sheet => {
  //           sheet.selectedCounter = props.selectedRowIds ? _.filter(sheet.values, v => props.selectedRowIds[v[props.ids[0]]]).length : sheet.counter
  //           sheet.children = _.values(_.pick(wb, ...sheet.childIds))
  //           return sheet
  //         })
  //         wb[selectedSheetId] ?? setSelectedSheetId("main") // current selectedSheetId may be not present if its node become unchecked
  //         setWorkbook(wb)
  //         setIsLoading(false)
  //       })
  //   },[
  //     props.collectorId,
  //     props.nodeIds,
  //     props.ids,
  //     props.options,
  //     props.checkedCohorts,
  //     // props.contents,
  //     props.liftUpHistoryData,
  //     props.selectedRowIds,
  //   ]
  // )

  const Sheet = ({
    isLoading,
    mainSheetRowId,
    datatableState,
    sheet,
    legend,
    selectedRowIds,
    handleDatatableStateChange,
  }) => {

    const columns = _.map(sheet.fields, (f, i) => ({
      id: f.name,
      Header: <strong>
        {f.name}
        {/*{` - ${i} `}*/}
        {f.name == sheet.keyName ? <sup className="text-danger">KEY</sup> : ""}
      </strong>,
      accessor: originalRow => _.isBoolean(originalRow[f.name]) ? String(originalRow[f.name]) : originalRow[f.name], // need to be custom if originalRow[name] is not a string,
      typeInfo: f
    }))

    const getCustomCellProps = cell => {
      const typeInfo = cell.column.typeInfo
      return typeInfo ?
        {
          className: typeInfo.contentType == "integer" || typeInfo.contentType == "float" || typeInfo.contentType == "boolean" ? "text-right" : null
        }
        :
        {}
    }

    const filteredData = sheet.isMain ?
      sheet.values
      :
      _.filter(sheet.values, row => selectedRowIds[row[mainSheetRowId]])

    return <>
      <DataTable
        columns={columns}
        data={filteredData}
        initialSelectedRowIds={selectedRowIds}
        initialHiddenColumns={_.map(_.filter(sheet.fields, "hidden"), "id")}
        datatableState={datatableState && {...datatableState[sheet.id]}}
        handleDatatableStateChange={args => handleDatatableStateChange({
          ...args,
          cId: props.model.id,
          sheetId: sheet.id
        })}
        loading={isLoading}
        getCustomCellProps={getCustomCellProps}
        // getCustomColumnProps={getCustomColumnProps}
        rowId={sheet.isMain ? mainSheetRowId : undefined}
        disableRowSelection={props.onlyRead}
      />
    </>
  }

  Sheet.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    mainSheetRowId: PropTypes.string.isRequired,
    datatableState: PropTypes.object,
    sheet: PropTypes.object.isRequired,
    handleDatatableStateChange: PropTypes.func.isRequired,
  }

  let treeData, treeActiveKey
  switch (sheetsNavMode) {
  case "path":
    treeData = _.map(workbook, (sheet, sheetId) => {
      return {
        key: sheetId,
        label: sheetId,
        selectedCounter: sheet.selectedCounter
      }
    })
    treeActiveKey = selectedSheetId
    break
  case "tree":
    treeData = [_.mapKeysDeep(_.mapValuesDeep(workbook["main"], value => _.pick(value, ["id", "name", "children", "selectedCounter"]), {childrenPath: "children"}), (v, k) => {
      switch (k) {
      case "id":
        return "key"
      case "name":
        return "label"
      case "children":
        return "nodes"
      default:
        return k
      }
    })]
    treeActiveKey = selectedSheetId == "main" ? "main" : _.compact(["main", ...selectedSheetId.split(".").slice(0, -1), selectedSheetId]).join("/")
    break
  case "excel":
    treeData = _.map(workbook, (sheet, sheetId) => {
      return {
        key: sheetId,
        label: sheet.excelName,
        selectedCounter: sheet.selectedCounter
      }
    })
    treeActiveKey = selectedSheetId
    break
  case "db":
    treeData = _.map(workbook, (sheet, sheetId) => {
      return {
        key: sheetId,
        label: sheet.tableName,
        selectedCounter: sheet.selectedCounter
      }
    })
    treeActiveKey = selectedSheetId
    break
  }

  // console.debug(treeData)

  const sheetTabLabel = function (name, counter) {
    return <span><i>{name}</i> <span className="badge badge-secondary">{counter}</span></span>
  }

  let emptyCardBody = (
    <div className="table-responsive">
      <table className="table table-sm table-bordered table-hover">
        <tbody role="rowgroup">
          <tr>
            <td colSpan="10000" className="lead text-center">
              <span><i className="fas fa-spinner fa-spin ml-2"></i> loading ...</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )

  const sheet = workbook[selectedSheetId] ?
    <>
      <Sheet
        {..._.pick(props, ["isLoading", "datatableState", "handleDatatableStateChange", "selectedRowIds", "legend"])}
        sheet={workbook[selectedSheetId]}
        mainSheetRowId={props.ids[0]}
      />
      <TreeMenu
        // key={sheetsNavMode}
        data={treeData}
        hasSearch={false}
        activeKey={treeActiveKey}
        disableKeyboard="true"
        onClickItem={item => setSelectedSheetId(item.key.split("/")[item.level])}
      >
        {({items}) =>
          <ul className={`rstm-tree-item-group sheets-nav-mode-${sheetsNavMode == "tree" ? "tree" : "flat"}`}>
            {items.map(({key, label, selectedCounter, ...props}) => (
              <ItemComponent key={key} {...props} label={sheetTabLabel(label, selectedCounter)}/>
            ))}
          </ul>
        }
      </TreeMenu>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="tooltip-tab-nav-mode">
            Sheets navigation mode
          </Tooltip>
        }
      >
        <ToggleButtonGroup
          size="sm"
          type="radio"
          name="tab-nav-mode"
          value={sheetsNavMode}
          className="mb-3"
          onChange={
            v => {
              setSheetsNavMode(v)
              setSelectedSheetId("main")
            }
          }
        >
          <ToggleButton variant="outline-secondary" value="path">path</ToggleButton>
          <ToggleButton variant="outline-secondary" value="tree">tree</ToggleButton>
          <ToggleButton variant="outline-secondary" value="excel">excel</ToggleButton>
          <ToggleButton variant="outline-secondary" value="db">db</ToggleButton>
        </ToggleButtonGroup>
      </OverlayTrigger>
      {
        _.keys(workbook).length > 1 ?
          <LiftUpManager
            workbook={workbook}
            liftUpHistory={liftUpHistory}
            disabled={props.isLoading || props.onlyRead}
            handleLiftUpHistoryChange={newLiftUpHistory => props.handleLiftUpHistoryChange({
              liftUpHistory: newLiftUpHistory,
              cId: props.model.id
            })}
            fieldNameRegexp={props.fieldNameRegexp}
          />
          :
          null
      }
    </>
    :
    null

  let selectedRowStatusAlert = null
  if (workbook) {
    const found = _.map(workbook["main"].values,props.ids[0])
    const selected = _.filter(_.keys(props.selectedRowIds), id => found.includes(id))
    if (found.length != selected.length) {
      selectedRowStatusAlert = <Alert variant='warning' className='mt-1 text-center'>
        <i className="fas fa-exclamation-triangle mr-1"></i>
        {
          _.isEmpty(selected) ?
            "Please select some records to enable export"
            :
            "Only selected records will be exported"
        }
      </Alert>
    }
  }

  const exportButtons =
    <Container fluid>
      <Row>
        <Col>
          {
            props.privileges.readExportDbs ?
              props.currentView && !props.isCurrentViewChangedContent ?
                <DatabaseWidget
                  projectId={props.projectId}
                  currentView={props.currentView}
                  collectorId={props.collectorId}
                  workbook={workbook}
                  exportParameters={exportParams}
                  canManage={props.privileges.manageExportDbs}
                />
                :
                <span className="btn btn-secondary btn-sm disabled">
                  <i className="fa fa-exclamation-circle"/> select a view (saved) to export workbook as database
                </span>
              :
              null
          }
        </Col>
        <Col>
          <ButtonToolbar bsPrefix="btn-toolbar float-right">
            <DropdownButton disabled={_.isEmpty(props.selectedRowIds)} className="mr-2" size="sm" variant="secondary"
              title={<span><i className="fas fa-download"/> download selected sheet</span>}>
              <Dropdown.Item onClick={() => exportCSV({
                data: workbook[selectedSheetId],
                selectedRowIds: props.selectedRowIds,
                personIdName: props.personIdName
              })}>CSV</Dropdown.Item>
              <Dropdown.Item onClick={() => exportExcel({
                data: workbook[selectedSheetId],
                selectedRowIds: props.selectedRowIds,
                personIdName: props.personIdName
              })}>Excel</Dropdown.Item>
            </DropdownButton>
            <DropdownButton disabled={_.isEmpty(props.selectedRowIds)} size="sm" variant="secondary"
              title={<span><i className="fas fa-download"/> download workbook</span>}>
              <Dropdown.Item onClick={() => exportCSV({
                data: _.values(workbook),
                filename: props.model.name,
                selectedRowIds: props.selectedRowIds,
                personIdName: props.personIdName
              })}>CSV</Dropdown.Item>
              <Dropdown.Item onClick={() => exportExcel({
                data: _.values(workbook),
                filename: props.model.name,
                selectedRowIds: props.selectedRowIds,
                personIdName: props.personIdName
              })}>Excel</Dropdown.Item>
            </DropdownButton>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          {selectedRowStatusAlert}
        </Col>
      </Row>
    </Container>

  return <Styles>
    <Card bsPrefix="card card-dark border-dark visualizer">
      <Card.Header>
        <Card.Title>
          <strong>{props.model.title}</strong>
        </Card.Title>
        <div className="card-tools">
          <button type="button" className="btn btn-tool" data-card-widget="maximize"><i className="fas fa-expand"></i>
          </button>
          <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i>
          </button>
        </div>
      </Card.Header>
      <Card.Body>
        {
          props.isLoading
            ? emptyCardBody
            : sheet
        }
      </Card.Body>
      <Card.Footer>
        {
          workbook.main.counter
            ? exportButtons
            : null
        }
      </Card.Footer>
    </Card>
  </Styles>
}

Visualizer.propTypes = {
  projectId: PropTypes.number,
  currentView: PropTypes.object,
  collectorId: PropTypes.number,
  workbook: PropTypes.object.isRequired,
  checkedCohorts: PropTypes.array,
  key: PropTypes.string,
  model: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onlyRead: PropTypes.bool,
  isCurrentViewChangedContent: PropTypes.bool,
  nodeIds: PropTypes.array.isRequired,
  contents: PropTypes.array,
  legend: PropTypes.array,
  ids: PropTypes.array.isRequired,
  personIdName: PropTypes.string.isRequired,
  selectedRowIds: PropTypes.object,
  handleDatatableStateChange: PropTypes.func.isRequired,
  handleLiftUpHistoryChange: PropTypes.func.isRequired,
  datatableState: PropTypes.object,
  options: PropTypes.object,
  liftUpHistoryData: PropTypes.array,
  fieldNameRegexp: PropTypes.instanceOf(RegExp).isRequired,
  privileges: PropTypes.object.isRequired,
}

Visualizer.defaultProps = {
  liftUpHistoryData: [],
  contents: [],
  legend: [],
  options: {},
  onlyRead: false,
  privileges: {}
}

function arePropsEqual(prevProps, nextProps) {
  return prevProps.nodeIds === nextProps.nodeIds
    && prevProps.checkedCohorts === nextProps.checkedCohorts
    && prevProps.isLoading === nextProps.isLoading
    && prevProps.ids === nextProps.ids
    && prevProps.viewId === nextProps.viewId
    && _.isEqual(prevProps.selectedRowIds,nextProps.selectedRowIds)
    && _.isEqual(prevProps.liftUpHistoryData,nextProps.liftUpHistoryData)
    && _.isEqual(prevProps.options,nextProps.options)
    && _.isEqual(prevProps.currentView,nextProps.currentView)
}

const memoizedVisualizer = React.memo(Visualizer,arePropsEqual)
memoizedVisualizer.displayName = "Visualizer"

export default memoizedVisualizer
